import React,{ useEffect, useState } from "react";
import firebase from "firebase";
import { db } from "../../firebaseConfig";
import NewsLine from "./newsline";
import { Accordion, Alert } from "react-bootstrap";


export default function ShowNews(){
    
    const [todoList, setTodoList] = useState();
   
  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref("page/topnews");
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

  }, []);


    return(
        <div>
          <div>
            <Alert variant="success">
            <center><b>All You need to Know</b></center>
            </Alert>
          </div>
      <div>
        <Accordion>
        {todoList
        ? todoList.map((todo, index) => 
        
        <Accordion.Item eventKey={index}>
                    <Accordion.Header>{todo.heading}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <p>{todo.description}</p>
                            
                        </div>
                    </Accordion.Body>
                </Accordion.Item>)
        : ''}
        
        </Accordion>
      </div>
        </div>
    );
}