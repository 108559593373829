import React, { useState, useContext } from "react";
import "./slideshow.css";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import Carousel from "react-bootstrap/Carousel";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";


import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";

export default function Slideshow({path}) {
  const [user, setUser] = useState("");
  const deletePost = () => {};

  const [posts, setPosts] = useState([]);
  db.collection(`${path}`).onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });

  
  return (
    <div className="show">
      <Carousel stopOnHover={false}  autoPlay={true}
              infiniteLoop={true}>
        {posts.map(({ id, post }) => {
          return (
            <Carousel.Item interval={300}>
              <Link to={post.onClickfunction} target="_blank">
              <img
                className="d-block w-100"
                src={post.slideshow_image}
                alt="Image One"
                style={{ height: "450px", width: "500px"}}
              />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      {/*<span style={{ fontWeight: "500", marginRight: "4px" }}>
            {slideshow_image}
  </span>*/}
    </div>
  );
}
