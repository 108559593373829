import React, { Component, useState } from 'react';
import ReactPlayer from 'react-player';

export default function PlayVideo() {

    const [videoURL, setVideoURL] =
        useState("https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/videos%2Fwhen%20first%20year%20medico%20says%20mbbs%20ke%20bad%20paisa%20hi%20paisa%20hoga.mp4?alt=media&token=83e53211-707c-4ba7-a063-8576e849e981");
    return (
        <div style={{}}>
            <video controls width="85%" autoPlay={"autoplay"} preload='true' loop>
                <source src={videoURL} type="video/mp4" />
            </video>

        </div>
    );
};

