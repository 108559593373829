import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import firebase from "firebase";
import { db } from "../firebaseConfig";
import "../index.css";
import FooterCS from "../FooterCS";
import HeaderGen from "../HeaderGen.js";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import ShowColumn from "../components/showColumn";
import HorizontalScrollSlider from "../components/horizontalScroll";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";
import Todo from "../components/base/Todo";
import Next from "../components/showBranchImages";
import ShowProducts from "../components/showProducts";
import Slideshow from "../medicalstudent/slideshow_medicalstudent";
import ShowBranches from "../components/showBranches";
import ShowExperts from "../components/showExperts";
import ShowMemes from "../components/showMemes";
import FooterNext from "../Footers/FooterNext";
import { Alert } from "react-bootstrap";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function Doctor() {
  const [startDate, setStartDate] = useState(new Date().toDateString());


  const [todoList, setTodoList] = useState();
  const [nextList, setNextList] = useState();

  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref("todo");
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

    const showImageRef = firebase.database().ref("next");
    showImageRef.on("value", (snapshot) => {
      const n = snapshot.val();
      const nextList = [];
      for (let id in n) {
        nextList.push({ id, ...n[id] });
      }
      setNextList(nextList);
    });

  }, []);



  const [post, setPosts] = useState([]);
  const [postsX, setPostsX] = useState([]);
  db.collection("slideshowschool").onSnapshot((snapshot) => {
    setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
  });

  return (
    <div>

      <div class="container" style={{ height: "400px" }}>
        <Slideshow path="slideshow/doctor/get" />
      </div>

      <div className="showImg">
      <HorizontalScrollSlider path="showtype" />
      </div>
      
      <div class="container" style={{marginTop:"15px", marginBottom:"15px"}}>
        <Alert variant="success"><p><b>
        Please note that Dr Planete is merely a platform where organizations (Hospitals, Clinics, Schools/Educational Institutes, Industries & Corporates etc.) can post request when they require services of a doctor.
        Dr Planete does not provide or claim to provide any kind of employment.
        </b> </p></Alert>
      </div>
      {/*<div style={{ marginTop: "35px" }}>
        <div class="container body-content">
          <div className="columns-2">
            {postsX.map(({ id, postsX }) => {
              return (
                <ShowColumn
                  key={id}
                  slideshow_image={postsX.slideshow_image}
                  onClickfunction={postsX.onClickfunction}
                />
              );
            })}
          </div>

        </div>
          </div>*/}
      <div style={{ marginTop: "35px", marginBottom: "35px" }}>
        <ShowMemes />
      </div>

     

      {/*<div>//working realtime database fetch
      {todoList
        ? todoList.map((todo, index) => <Todo todo={todo} key={index} />)
        : ''}
      </div>*/}

      {/*<div>//next working realtime database fetch
      {nextList
        ? nextList.map((next, index) => <Next next={next} key={index} />)
        : ''}
      </div>*/}

      <ShowBranches specialization="nice" />
      <FooterNext />
    </div>
  );
}

export default Doctor;
