import React from "react";
import { cheq, storage, db } from "../firebaseConfig";
import firebase from "firebase";
import { useState } from "react";
import Header from "../HeaderGen";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { MDCTextField } from "@material/textfield";
import { TextField } from "@material-ui/core";
import Home from "../Home";
import './hospital.css';
import Background from '../background.js'
import FooterDH from "../FooterDH";
import HeaderGen from "../HeaderGen.js";

import ShowColumn from "../components/showColumn";
import Slideshow from "../medicalstudent/slideshow_medicalstudent";
import ShowProducts from "../components/showProducts";
import FooterNew from "../Footers/Footer";
import GetApp from "../Footers/getApp";
import ShowExperts from "../components/showExperts";

import GrowPlusPricing from "../products/pricing";
import PostJob from "./postJob/postJob";
import ShowMemes from "../components/showMemes";
import FooterNext from "../Footers/FooterNext";



const Hospital = () => {
  const logout = () => {
    cheq.signOut();
  };

  const [startDate, setStartDate] = useState(new Date().toDateString());


  const [postsX, setPostsX] = useState([]);
  db.collection("second/hospital/options").onSnapshot((snapshot) => {
    setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
  });
  //

  return (
    <div>
      <div class="container" style={{ marginBottom: "45px" }}>
        <ShowProducts path="slideshow/hospital/get" />
      </div>

      <div style={{ marginTop: "135px" }}>
        <div class="container body-content">
          <div className="columns-2" style={{marginTop:"5px", marginBottom:"5px"}}>
            {postsX.map(({ id, postsX }) => {
              return (
                <div >
                <ShowColumn
                  key={id}
                  slideshow_image={postsX.slideshow_image}
                  onClickfunction={postsX.onClickfunction}
                />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      

      <div class="container" style={{ marginTop: "35px", marginBottom: "25px", marginLeft: "45px", marginRight: "45px", width:"auto" }}>
        <Alert variant="info"><center><b>Start Growing Today with Dr Planete's Grow+ Plans</b></center></Alert>
      </div>


      <div className="container" style={{ marginTop: "35px", marginBottom: "25px" }}>
        <GrowPlusPricing />
      </div>


      <div  style={{ marginTop: "35px", marginBottom: "35px" }}>
        <ShowMemes />
      </div>

      {/*<div style={{ marginTop: "35px" }}><GetApp page="hospital" date={startDate} /></div>*/}

      <div><FooterNext /></div>
    </div>
  );
};

export default Hospital;