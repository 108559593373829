import React, { Component } from "react";
import "./OnTop.css";
import {
  Navbar,
  NavDropdown,
  Dropdown,
  Form,
  FormControl,
  Button,
  Nav,
} from "react-bootstrap";

import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

import { useStateValue } from "../StateProvider";
import { cheq } from "../firebaseConfig";
import { MenuItems } from "../MenuItems";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Doctor from "../doctor/Doctor.js";
import Home from "../Home";
import Login from "../Login";
import Hospital from "../hospital/Hospital.js";
import DoctorRegistration from "../doctor/doctorreg";
import MedicalStudent from "../medicalstudent/MedicalStudent";

import DashboardDoctor from "../doctor/dashboardDoctor";
import RequestVisitingDoctor from "../school_corporate/safeplan.js";
import DoctorRegistrationPortal from "../doctor/doctorRegistrationPortal";
import Termsandcondtions from "../termsandconditions/termsandconditions";
import PrivacyPolicy from "../termsandconditions/privacypolicy";

import background from "../img/ontop.png";
class OnTop extends Component {
  state = {
    clicked: false,
  };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <div className="top_strip">
            {/*<div className="strip_hello">
              <p style={{color: "white"}}>
                Welcome to Dr Planete, India's First Open Platform connecting
                doctors to hospitals,schools & industries!!
              </p>
    </div>*/}

            <div className="strip_hello-mobile">
              <center>
                <p style={{color:"white", marginTop:"0px"}}><b>We are coming up with a new look</b></p>
              {/*<p style={{color:"white"}}><b>Welcome to Dr Planete</b></p>
              <p style={{color:"white", marginTop:"-10px"}}><b>India's First Open Platform connecting
                doctors to hospitals,schools & industries!!</b></p>*/}
                </center>
            </div>
      </div>
    );
  }
}

export default OnTop;
